import * as Sentry from '@sentry/nextjs';

try {
  Sentry.init({
    dsn: 'https://XXXXXXXXXXXXX.ingest.sentry.io/YYYYYYYYYY',
    debug: true,
    environment: 'dev',
  });
} catch (error) {
  console.error(error);
}